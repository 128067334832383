.flex-center {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.fixed-row {
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.payment-container {
  padding: 1rem 1rem 0.5rem;
  border-radius: 2px;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, .1);
}

.svg-wrp > img {
  padding: 0 1rem;
  max-width: 80px;
}

.small {
  font-size: 12px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.payment-disabled {
  filter: grayscale(100%);
}

.payment-disabled > label {
  background-color: rgba(0.1, 0.1, 0.1, 0.1);
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, .1);
}

.height-anim {
  max-height: 3000px;
  opacity: 1;
  transition: max-height 0.75s ease-out;
  overflow: visible;
}

.height-anim-closing {
  opacity: 0;
  max-height: 0 !important;
  overflow-y: hidden;
}

.blik-logo {
  width: 102px;
  height: auto;
}

.blik-form {
  margin: 45px 0 40px;
}

.blik-form .blik-code {
  width: 260px;
  margin-left: auto;
  margin-right: auto;
}

.error-msg-text {
  position: relative;
  white-space: nowrap;
  padding: 0 20px 0 0;
  margin: 0.5rem 0;
  color: #d03737;
  line-height: 16px;
}

.error-msg-text::after {
  content: "";
  color: #d03737;
  display: block;
  font-family: icomoon;
  font-size: 15px;
  position: absolute;
  right: 0;
  top: 0;
}

.d-block {
  display: block !important;
}

.payment-color-error {
  color: #d03737;
}

.payment-btn-error {
  box-shadow: 0 0 4px 2px rgba(196, 64, 64, .5);
}

/* Apple Pay Btn */

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
  }
  .apple-pay-button-black {
    -apple-pay-button-style: black;
  }
  .apple-pay-button-white {
    -apple-pay-button-style: white;
  }
  .apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 5px;
    padding: 0px;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 32px;
    max-height: 64px;
  }
  .apple-pay-button-black {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
  }
  .apple-pay-button-white {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }
  .apple-pay-button-white-with-line {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
    border: .5px solid black;
  }
}

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-btn { /* also: plain, buy, check-out, book, or subscribe */
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: plain;
  }
}



html.modal-open body {
  overflow:hidden
}
@media screen and (max-width:39.99875em) {
  html.modal-open body {
    position:fixed
  }
}

.page-wrapper.page-layer:before {
  content:"";
  position:fixed;
  display:block;
  top:0;
  right:0;
  bottom:0;
  left:0;
  width:100%;
  height:100%;
  opacity:0;
  visibility:hidden;
  background:rgba(0,0,0,.5);
  z-index:1050;
  transition:.2s
}
.page-wrapper.page-layer.layer-visible {
  height:100%
}
.page-wrapper.page-layer.layer-visible:before {
  opacity:1;
  visibility:visible
}
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}
